<template>
  <secured-area :operations="['List_Operations', 'View_Operation', 'Edit_Operation', 'Archive_Operation', 'Unarchive_Operation', 'Remove_Operation', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles', 'View_Users_Rights']">
    <fieldset class="fieldset" v-show="filtersEnabled">
      <legend>Filtre(s)</legend>
    </fieldset>
    <b-table small hover :items="itemsList" :fields="columns" class="thisTable" responsive>

        <template #cell(selector)="data">
            <b-button size="sm" :disabled="data.item.cannotBeSelected" @click="select(data.item)">Select.</b-button>
        </template>

        <template #cell(name)="data">
          <span class="name">{{ data.item.name}}</span>
        </template>
        <template #cell(dislayName)="data">
          <span class="display-name">{{ data.item.displayName}}</span>
        </template>
        <template #cell(description)="data">
          <span class="description">{{ data.item.description }}</span>
        </template>
        <template #cell(active)="data">
          <b-checkbox v-model="data.item.active" :disabled="true" />
        </template>

        <template #cell(commands)="data">
            <row-commands
              :rowItem = "data.item.name"

              :viewBtnVisible="canView"
              @viewBtnClick="view"

              :editBtnVisible="!viewOnlyMode && canEdit"
              @editBtnClick="edit"

              :archiveBtnVisible="!viewOnlyMode && canArchive && data.item.isActive"
              @archiveBtnClick="confirmArchiving"

              :unarchiveBtnVisible="!viewOnlyMode && canUnarchive && !data.item.isActive"
              @unarchiiveBtnClick="unarchive"

              :deleteBtnVisible="!viewOnlyMode && canRemove && !data.item.isActive"
              :deleteBtnDisabled="data.item.cannotBeDeleted"
              @deleteBtnClic="confirmDeletion"
            />
        </template>
    </b-table>

    <b-modal
      v-model="showArchivingConfirmationDialog"
      title = "Confirmation d'archivage"
      @ok="archive"
      @cancel="operationToArchive=null"
      ok-variant="warning"
      ok-title="Oui, archiver"
      cancel-variant="secondary"
      cancel-title="Non"
    >
      <p>Etes-vous sûr de vouloir archiver cette opération ?</p>
    </b-modal>
    <b-modal
      v-model="showDeletionConfirmationDialog"
      title="Confirmation de suppression"
      @ok="remove"
      @cancel="operationToDelete=null"
      ok-variant="danger"
      ok-title="Oui, supprimer"
      cancel-variant="secondary"
      cancel-title="Non"
    >
      <p>Etes-vous sûr de vouloir supprimer cette opération ?</p>
      <p><b>ATTENION !</b>Cette opération est irréversible.</p>
    </b-modal>
  </secured-area>
</template>

<script>
import RowCommands from "@/components/Lists/RowCommands.vue";
import { mapActions } from "vuex";

export default {
  components: { RowCommands },
  props: {
    viewOnlyMode: { type: Boolean, default: false },
    items: { type: Array },
    withConfirmationOnArchiving: { type: Boolean, default: true},
    withConfirmationOnDeletion: { type: Boolean, default: true},
    nameColumnVisible: { type: Boolean, default: true },
    displayNameColumnVisible: { type: Boolean, default: true },
    descriptionColumnVisible: { type: Boolean, default: true },
    isActiveColumnVisible: { type: Boolean, default: true },
    filtersEnabled: { type: Boolean, default: false },
  },
  data(){
    return {
      showArchivingConfirmationDialog: false,
      showDeletionConfirmationDialog: false,
      operationToArchive: null,
      operationToDelete: null,
      canView: false,
      canEdit: false,
      canArchive: false,
      canUnarchive: false,
      canRemove: false,
    }
  },
  computed:{
    columns(){
      var ret = [];

      if(this.nameColumnVisible)
        //ret.push({key: 'selector', sortable: false, label: '', class:"selector", stickyColumn: true});
        ret.push({key: 'name', sortable: true, label: 'Nom (code)', stickyColumn: true});

      if(this.displayNameColumnVisible)
        ret.push({ key: 'displayName', sortable: true, label: 'Libellé'});

      if(this.descriptionColumnVisible)
        ret.push({ key: 'description', sortable: false, label: 'Description'});

      if(this.isActiveColumnVisible)
        ret.push({ key: 'isActive', sortable: true, label:'Actif'});

      ret.push({ key: 'commands', sortable: false, label: ''});

      return ret;
    },
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    view(item){
      this.$emit('view', item);
    },
    edit(item){
      this.$emit('edit', item);
    },
    confirmArchiving(item)
    {
      this.operationToArchive = item;
      if(this.withConfirmationOnArchiving){
        this.showArchivingConfirmationDialog = true;
      } else {
        this.archive();
      }
    },
    //archive(item){
    //  this.$emit('archive', item);
    //},
    archive(){
      this.$emit('archive', this.operationToArchive);
      this.operationToArchive = null;
    },

    unarchive(item){
      this.$emit('unarchive', item);
    },

    confirmDeletion(item){
      this.operationToDelete = item;
      if(this.withConfirmationOnDeletion){
        this.showDeletionConfirmationDialog = true;
      }else{
        this.remove();
      }
    },
    //remove(item){
    //  this.$emit('remove', item);
    //},
    remove(){
      this.$emit('remove', this.operationToDelete);
      this.operationToDelete = null;
    },

    async itemsList(){
      this.canView = await this.userCan('View_Operation');
      this.canEdit = await this.userCan('Edit_Operation');
//      this.canArchive = await this.userCan('Archive_Operation');
//      this.canUnarchive = await this.userCan('Unarchive_Operation');
      this.canRemove = await this.userCan('Remove_Operation');
      this.canArchive = this.canRemove;
      this.canUnarchive = this.canRemove;

      var ret = [];

      if(this.items != null){
        ret = this.items.map(element => {
          return {
            name: element.name,
            displayName: element.displayName,
            description: element.description,
            isActive: (element.isActive == 1),
            isDeletable: !element.isInRole,
            cannotBeDeleted: true,
          }
        });
      }

      return ret;
    }
  }
}
</script>
