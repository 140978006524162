<template>
  <secured-area :operations="['List_Operations', 'View_Operation', 'Edit_Operation', 'Archive_Operation', 'Unarchive_Operation', 'Remove_Operation', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles', 'View_Users_Rights']">
    <h1>Opérations</h1>
    <operations-list
      :items = "operations"
      @view = "onViewItem"
      @edit = "onEditItem"
      @archive = "onArchiveItem"
      @unarchive = "onUnarchiveItem"
      @remove = "onRemoveItem"
    />
  </secured-area>
</template>

<script>
import OperationsList  from '@/components/Security/Operations/List.vue';
import axios from 'axios';
import SecuredArea from '@/components/Security/SecuredArea.vue';

export default {
  components: { OperationsList, SecuredArea },
  data(){
    return {
      operations: [],
    }
  },
  methods:{
    onViewItem(item){
      console.log('onViewItem', item);
    },
    onEditItem(item){
      console.log('onEditItem', item);
      this.$showLoader()
        .then(() => {
          this.$router.push({ name: 'SecurityOperationEdit', params: { id: item}});
        });
    },
    onArchiveItem(item){
      this.$showLoader()
        .then(() => {
          var url = this.getUrl('/api/Security/archiveOperation.php');
          axios.post(url, { operation_id: item.name })
        })
      console.log('onArchiveItem', item);
    },
    onUnarchiveItem(item){
      console.log('onUnarchiveItem', item);
    },
    onRemoveItem(item){
      console.log('onDeleteItem', item);
    },
    refresh(){
      var url = this.getUrl('/api/Security/getOperations.php');

      axios.get(url)
        .then((response) => {
          this.operations = response.data.operations;
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  mounted(){
    this.$hideLoader();
    this.refresh();
  }
}
</script>
